// import showdown from 'showdown'
import showdown from '@/lib/utils/showdown';
import { hasData } from './hasData';
import { linkifyUsername } from './linkify';
import { textProcess } from './textprocess';


function getConverter(message,server_website) {

    var converter = new showdown.Converter()
    converter.setOption('omitExtraWLInCodeBlocks', 'true');
    converter.setOption('simplifiedAutoLink', 'true');
    converter.setOption('excludeTrailingPunctuationFromURLs', 'true');
    converter.setOption('strikethrough', 'true');
    converter.setOption('simpleLineBreaks', 'true');
    converter.setOption('ghMentions', 'true'); //we do this manually below so dont use the native version (so we can capture lowercase urls correctly)
    converter.setOption('ghMentionsLink', `${server_website}/{u}`);
    converter.setOption('encodeEmails', 'true');
    converter.setOption('openLinksInNewWindow', 'true');
    converter.setOption('emoji', 'true');
    converter.setOption('requireSpaceBeforeHeadingText', 'true');
    converter.setOption('emoji', 'true'); //custom
    converter.setFlavor('github');

    return converter.makeHtml(textProcess(message)).toString()
}

export default function parseMarkDown(message,server_website) {

    if (!hasData(message)) return null;

    var varn = getConverter(message,server_website);

    varn = linkifyUsername(varn) //@github style username replacement instead of markdown's native one

    return varn


}

/*

SHOWDOWN OPTIONS
===================

CONVERTER OPTIONS
===================
backslashEscapesHTMLTags
completeHTMLDocument
disableForced4SpacesIndentedSublists
emoji
encodeEmails
excludeTrailingPunctuationFromURLs
ghCodeBlocks
ghCompatibleHeaderId
ghMentions
ghMentionsLink
headerLevelStart
literalMidWordAsterisks
literalMidWordUnderscores
metadata
noHeaderId
omitExtraWLInCodeBlocks
openLinksInNewWindow
parseImgDimensions
prefixHeaderId
rawHeaderId
rawPrefixHeaderId
requireSpaceBeforeHeadingText
simpleLineBreaks
simplifiedAutoLink
smartIndentationFix
smoothLivePreview
splitAdjacentBlockquotes
strikethrough
tables
tablesHeaderId
tasklists
underline

*/